import * as React from 'react'

import Page from '../components/layouts/Page'
import Container from '../components/layouts/Container'
import { graphql } from 'gatsby'
import { ImprintQuery } from '../graphql-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from '../styles/styled'

interface ImprintProps {
  data: ImprintQuery
}

const ImprintHeader = styled.h1`
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  margin-bottom: ${(props) => props.theme.dimensions.bottomMargin}rem;
`

const Imprint: React.FC<ImprintProps> = ({ data }: ImprintProps) => (
  <Page>
    <Container className="imprint-template">
      <ImprintHeader>{data?.mdx?.frontmatter?.title || 'n/a'}</ImprintHeader>
      <MDXRenderer>{data?.mdx?.body || 'n/a'}</MDXRenderer>
    </Container>
  </Page>
)

export default Imprint

export const query = graphql`
  query Imprint($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      id
      body
      fields {
        locale
      }
      frontmatter {
        title
      }
    }
  }
`
