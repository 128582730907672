import * as React from 'react'
import styled from '../../styles/styled'

const StyledPage = styled.div`
  display: flex;
  position: relative;
  padding: 30px 0 60px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    padding: 50px 0 60px;
  }
  flex-wrap: wrap;
  flex: 1 1 100%;
  height: 100%;
`

interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({ children, className }) => {
  return <StyledPage className={className}>{children}</StyledPage>
}

export default Page
